import React from "react";
import Button from "../../atoms/Button";
import "../../../assets/styles/components/modules/formulars/modale.scss";
import Article from "../article";
import { navigate } from "gatsby";
const Modale = ({valid, data, text, noCallBack, isAddToCart=true, yesCallback})=> {
    React.useEffect(()=>{
        document.querySelector('.modale').style.top = `calc(${window.scrollY}px + 50vh)`
        document.querySelector('body').style.height = `100vh`
        document.querySelector('body').style.overflow = `hidden`
        return () => {
            document.querySelector('body').style.height = `fit-content`
            document.querySelector('body').style.overflow = `scroll`
            
        }
    }, [])
    console.log(data)
    return <div className="modale">
        <div className="content">
            <h3>
                {
                    text ? `${text}` :'Article ajouté au panier !'
                }
            </h3>
            {isAddToCart && <Article display="onCart" data={data} interaction={false}/>}
            <div className={`btn_container ${isAddToCart ? "" : "confirmation"}`}>
                {yesCallback && <Button type={isAddToCart ? "thin" : "text"} color={isAddToCart ? "" : "green"} callBack={()=>yesCallback()} content="Retourner a la boutique"/>}
                {isAddToCart && <Button color="green" type="text" callBack={()=>{navigate('/recapitulatif-commande/');yesCallback()}} content="Finaliser ma commande"/>}
            </div>
        </div>
    </div>
}

export default Modale