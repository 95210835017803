import React from "react";
import Button from "../atoms/Button"
import "../../assets/styles/utils/variables.scss"
import "../../assets/styles/components/modules/header.scss"
import { setCart, setMenu } from "../../assets/scripts/store/redux-slices/modals";
import { useSelector, useDispatch } from "react-redux";
import { Link, navigate } from "gatsby"


const Header = (props) => {
    const cart = useSelector((state) => state.cart)
    // let navigate = useNavigate();
    const authentication = useSelector((state) => state.authentication)

    const isMenuOpen = useSelector(state => state.modals.menu)
    const dispatch = useDispatch();
    const openCart = () => {
        dispatch(setCart(true))
    }
    const openMenue = () => {
        dispatch(setMenu(!isMenuOpen))
    }
    const pushToLogin = () => {
        navigate(`/se-connecter`);
    }

    React.useEffect(() => {

        document.querySelector("header").style.top = "0px";

    
    
      },[cart.quantity])

    React.useEffect(() => {

        let lastScroll = 0
        let currentScroll = 0
        document.addEventListener("scroll", () => {
            const count = document.querySelector(".count")
            const header = document.querySelector("header")
            currentScroll = window.scrollY;
            if (header) {
                if (lastScroll > currentScroll && currentScroll) {

                    document.querySelector("header").style.top = "0px";
                } else {

                    document.querySelector("header").style.top = "-125px";


                }


                lastScroll = currentScroll

            }

        })
    }, [])
    return (
        <header>
            <nav>
                <Link className="header-stodac nav-link" to={`/`} aria-label="Stodac">

                    <svg width="78" height="18" viewBox="0 0 78 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1132_337)">
                            <path d="M24.8403 17.1062C23.7349 16.5307 22.8531 15.7348 22.1948 14.7307C21.5365 13.7144 21.2012 12.5878 21.2012 11.3266C21.2012 10.3837 21.3875 9.50211 21.7725 8.69395C22.1575 7.88578 22.6792 7.17558 23.3499 6.57558C24.033 5.97558 24.8031 5.51027 25.6601 5.19191C26.5419 4.87354 27.4486 4.71436 28.3677 4.71436C29.2993 4.71436 30.2059 4.87354 31.0878 5.19191C31.9448 5.49803 32.7149 5.95109 33.398 6.56333C34.0811 7.17558 34.6028 7.88578 34.9754 8.69395C35.3604 9.50211 35.5467 10.3837 35.5467 11.3266C35.5467 12.2817 35.3604 13.1633 34.9878 13.9593C34.6152 14.7674 34.0811 15.4776 33.398 16.1021C32.7024 16.7144 31.9324 17.1797 31.0878 17.4858C30.2308 17.8164 29.3241 17.9756 28.3677 17.9756C27.1381 17.9756 25.9582 17.6939 24.8403 17.1062Z" fill="#D2EA8E" />
                            <path d="M8.85585 4.98352C8.34662 4.50597 7.89948 4.17536 7.52687 3.97944C7.117 3.77127 6.64502 3.66107 6.11095 3.66107C5.7135 3.66107 5.34088 3.78352 4.98069 4.04066C4.60808 4.28556 4.4342 4.61617 4.4342 5.0325C4.4342 5.60801 4.83165 6.07331 5.63897 6.40393C6.45872 6.74678 7.29088 7.08964 8.14789 7.40801C9.01732 7.75087 9.76254 8.2774 10.396 9.01209C11.0294 9.74678 11.3399 10.8366 11.3399 12.2692C11.3399 14.106 10.781 15.5141 9.66318 16.4937C8.55776 17.4856 7.14184 17.9754 5.41541 17.9754C4.42178 17.9754 3.45299 17.8039 2.52146 17.4733C1.57751 17.1305 0.757766 16.6284 0.0498047 15.9549L2.34757 12.7223C3.08037 13.6407 4.04916 14.0937 5.25394 14.0937C5.73834 14.0937 6.19789 13.959 6.6326 13.6896C7.06732 13.4325 7.27846 13.0529 7.27846 12.5509C7.27846 11.9631 6.86859 11.4978 6.06127 11.1549C5.70108 11.008 5.2912 10.8243 4.80681 10.6407L4.17337 10.3835L3.52751 10.1141C2.65808 9.75903 1.91286 9.22025 1.29184 8.51005C0.670823 7.79985 0.360314 6.74678 0.360314 5.33862C0.360314 4.26107 0.633562 3.31821 1.16764 2.51005C1.68929 1.71413 2.38483 1.10189 3.26668 0.673319C4.11127 0.256992 5.03037 0.0488281 5.99916 0.0488281C6.89343 0.0488281 7.77528 0.220257 8.64471 0.55087C9.52655 0.905972 10.2966 1.39576 10.9549 2.02025L8.85585 4.98352Z" fill="black" />
                            <path d="M13.5756 8.51027H10.9922L11.874 5.20415H13.5756V0.453125L17.488 0.477615V5.20415H20.0839L19.2145 8.51027H17.488V12.6123C17.488 13.7021 18.1836 14.2531 19.5747 14.2531C20.0218 14.2531 20.3696 14.2041 20.6056 14.0939L19.4753 17.9388C19.2766 17.9756 19.0654 17.9878 18.8791 17.9878C17.7116 17.9878 16.7428 17.8041 15.9603 17.4368C15.1779 17.0694 14.5817 16.5062 14.1842 15.7592C13.7868 15.0245 13.588 14.0694 13.588 12.9184V8.51027H13.5756Z" fill="black" />
                            <path d="M50.5513 11.4243C50.5389 12.6488 50.216 13.7509 49.5577 14.7427C48.8994 15.7468 48.0176 16.5427 46.9122 17.106C45.8067 17.6815 44.6268 17.9754 43.3723 17.9754C42.416 17.9754 41.5093 17.8162 40.6523 17.4856C39.8077 17.1794 39.0376 16.7141 38.3421 16.1019C37.659 15.4774 37.1249 14.7794 36.7523 13.9713C36.3797 13.1754 36.1934 12.2937 36.1934 11.3386C36.1934 10.408 36.3797 9.52637 36.7647 8.71821C37.1373 7.91004 37.6714 7.21209 38.3421 6.59984C39.0252 5.9876 39.7953 5.52229 40.6523 5.21617C41.5341 4.8978 42.4408 4.75086 43.3723 4.75086C44.2915 4.75086 45.1981 4.91004 46.08 5.21617L46.3657 5.33862L46.6513 5.46106V0.367188L50.5637 0.379434V11.4243H50.5513ZM46.6389 11.3386C46.6389 10.7754 46.4899 10.2611 46.1918 9.79576C45.8937 9.31821 45.4838 8.93862 44.9746 8.65698C44.4778 8.37535 43.9437 8.22841 43.3723 8.22841C42.801 8.22841 42.2669 8.37535 41.7577 8.65698C41.2485 8.93862 40.851 9.31821 40.5405 9.79576C40.2424 10.2611 40.0934 10.7754 40.0934 11.3386C40.0934 12.1958 40.4163 12.9427 41.0746 13.5549C41.7204 14.1794 42.4905 14.4856 43.3723 14.4856C44.2542 14.4856 45.0118 14.1794 45.6577 13.5549C46.316 12.9427 46.6389 12.208 46.6389 11.3386Z" fill="black" />
                            <path d="M61.6296 17.2163C61.3687 17.3265 61.1824 17.4 61.0582 17.449C60.1888 17.7796 59.2821 17.9388 58.3382 17.9388C57.0837 17.9388 55.9038 17.6571 54.7984 17.0816C53.6929 16.5061 52.8111 15.7102 52.1528 14.7183C51.4945 13.702 51.1592 12.5755 51.1592 11.3265C51.1592 10.3837 51.3455 9.50203 51.7305 8.69387C52.1155 7.88571 52.6372 7.18774 53.3079 6.58774C53.9662 5.98774 54.7363 5.53469 55.6057 5.22857C56.4751 4.89795 57.3818 4.73877 58.3257 4.73877C59.2573 4.73877 60.164 4.89795 61.0458 5.21632C61.9277 5.53469 62.6977 5.99998 63.3436 6.58774C64.0267 7.19998 64.5608 7.89795 64.9334 8.69387C65.3184 9.50203 65.5047 10.3837 65.5047 11.3265L65.4923 17.6204L61.6047 17.5837V17.2163H61.6296ZM55.5187 9.77141C55.2206 10.249 55.0716 10.7633 55.0716 11.3265C55.0716 12.1837 55.3945 12.9183 56.0528 13.5306C56.6987 14.1428 57.4563 14.449 58.3382 14.449C59.2324 14.449 59.9901 14.1428 60.6359 13.5306C61.2942 12.9061 61.6171 12.1714 61.6171 11.3265C61.6171 10.7633 61.4681 10.249 61.17 9.77141C60.8719 9.29386 60.462 8.91428 59.9528 8.63265C59.456 8.36326 58.9219 8.21632 58.3382 8.21632C57.7792 8.21632 57.2452 8.35101 56.7359 8.63265C56.2267 8.91428 55.8168 9.29386 55.5187 9.77141Z" fill="black" />
                            <path d="M75.7896 8.95101C75.4418 8.65714 75.0568 8.44896 74.6345 8.30202C74.1998 8.15508 73.7402 8.08163 73.2683 8.08163C72.374 8.08163 71.6163 8.36326 70.9953 8.93877C70.3619 9.51428 70.0514 10.2245 70.0514 11.0694C70.0514 11.7306 70.188 12.3183 70.4612 12.8449C70.7221 13.3714 71.1071 13.7877 71.6288 14.0939C72.1256 14.4122 72.6969 14.5592 73.3552 14.5592C74.324 14.5592 75.1437 14.253 75.8268 13.653L77.9756 16.3347C77.6651 16.653 77.2303 16.9469 76.6714 17.1918C76.1125 17.4367 75.5288 17.6204 74.9326 17.7428C74.3364 17.8653 73.7899 17.9265 73.2807 17.9265C71.8647 17.9265 70.6103 17.6326 69.5173 17.0449C68.4367 16.4449 67.6046 15.6735 67.0208 14.7061C66.437 13.7265 66.1514 12.6735 66.1514 11.5592C66.1514 10.5429 66.3253 9.61224 66.673 8.77959C67.0332 7.93469 67.53 7.21224 68.1511 6.61224C68.7721 6.02448 69.5297 5.55918 70.4116 5.22857C71.2934 4.89795 72.2498 4.73877 73.2683 4.73877C74.1004 4.73877 74.9077 4.87346 75.7026 5.13061C76.4975 5.39999 77.2055 5.76734 77.8265 6.25714L75.7896 8.95101Z" fill="black" />
                            <path d="M73.0569 13.5307C74.2437 13.5307 75.2057 12.5823 75.2057 11.4123C75.2057 10.2424 74.2437 9.29395 73.0569 9.29395C71.8702 9.29395 70.9082 10.2424 70.9082 11.4123C70.9082 12.5823 71.8702 13.5307 73.0569 13.5307Z" fill="#D2EA8E" />
                            <path d="M31.436 9.44063C31.4112 10.5549 30.8647 11.0202 30.8647 11.0202C30.815 8.54675 27.8217 5.90186 27.8217 5.90186C28.8029 7.54267 24.4434 9.61206 24.4434 12.5753C24.4434 14.4365 27.2504 15.7957 28.6539 15.5631C34.7274 14.5467 31.436 9.44063 31.436 9.44063Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1132_337">
                                <rect width="78" height="18" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>


                </Link>
                <ul className="links">
                    <li>
                        <Link className="nav-link" to={`/boutique`}>Boutique</Link>
                    </li>
                    {
                        authentication.data.admin && (
                            <>
                                <li>
                                    <Link className="nav-link" to={`/admin/articles`}>Articles</Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to={`/admin/commandes`}>Commandes</Link>
                                </li>
                            </>
                        )
                    }
                    {
                        !authentication.data.admin && (
                            <>
                                <li>
                                    <Link className="nav-link" to={`/mon-espace`}>Mon espace</Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to={`/assistance`}>Assistance</Link>
                                </li>
                            </>
                        )
                    }
                </ul>
                <ul className="buttons">
                    <li className="nav-link">
                        <Button type="svg" callBack={openCart} content="cart" />
                        <span className="count">{cart.quantity > 0 && cart.quantity}</span>

                    </li>
                    <li className="nav-link"  >
                        {/* <Link className="nav-link" to={`/se-conecter`}> */}
                        <Button callBack={pushToLogin} type="svg" content="login" />
                    </li>
                    <li>
                        {
                            !isMenuOpen ?
                                <Button type="svg" callBack={openMenue} content="menu" />
                                :
                                <Button type="svg" callBack={openMenue} content="crossMenu" />
                        }
                    </li>
                </ul>
            </nav>
        </header>
    )
}
export default Header