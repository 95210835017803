import { createSlice } from "@reduxjs/toolkit";
import eventBus from "../../utils/eventBus.js"

    let cart={
      cart: [],
      deliveryMode: null,
      payementMode: null,
      deliveryPrice: null,
      deliveryWeight: null,
      quantity: 0,
      total: null,
      commandInfo: {
        facturation: {
          lastName: "",
          firstName: "",
          corporation:'',
          streetNumber:"",
          street:"",
          city: "",
          postCode: "",
          complement: ""
        },
        programation: {
          manufacturer: "", 
          model:"", 
          serialNumber:""
        },
        deliveryInfo: {
          streetNumber:"",
          street:"",
          city: "",
          postCode: "",
          complement: ""
        }
      }
    }

const getProductsById = (products, id) => {
  return products.find((p) => p.slug.current === id);
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: cart.cart,
    deliveryMode: cart.deliveryMode,
    payementMode: cart.payementMode,
    deliveryPrice: cart.deliveryPrice,
    quantity: cart.quantity,
    total: cart.total,
    commandInfo: cart.commandInfo,
    deliveryWeight: cart.deliveryWeight,
    programation: cart.programation,
    email: ""
  },
  reducers: {
    addProductToCart: (state, action) => {
      const products = state.cart;
      const product = action.payload;

      const existingProduct = getProductsById(state.cart, product.slug.current);
      console.log(JSON.stringify(state.cart))
      let newState = [];
      if (existingProduct) {
        newState = products.map((p) => {
          if (p._id === existingProduct._id) {
            p.quantity = p.quantity + product.quantity;
          }
          return p;
        });
        state.cart = newState;
      } else {
        state.cart = [...products, product];
      }
      eventBus.dispatch("addProduct", {product})

      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        deliveryWeight: state.deliveryWeight,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        email : state.email
      }));
    },
    removeProductFromCart: (state, action) => {
      // declare variables
      let products = state.cart;
      let product = action.payload;
      console.log(product)
      //
      const newProducts = products.filter((p) => p.slug.current !== product.slug.current);

      state.cart = newProducts;

      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
    },
    removeAllProductsFromCart: (state) => {
      state.cart = [];
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
    },
    checkQuantity: (state, action) => {
      // declare variables
      let products = state.cart;
      
      if (products.length > 0) {
        let quantity = 0;
        for (let index = 0; index < products.length; index++) {
          const element = products[index];
          quantity = quantity + element.quantity;
        }
        state.quantity = quantity;
      } else {
        state.quantity = 0;
      }
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
    },
    increaseQuantity: (state, action) => {
      // declare variables
      let products = state.cart;
      let product = action.payload.data;
      //
      const existingProduct = getProductsById(state.cart, product.slug.current);
      let newState = [];
      if (existingProduct) {
        console.log("add")
        newState = products.map((p) => {
          if (p.slug.current === existingProduct.slug.current) {
            p.quantity = p.quantity + 1;
          }
          return p;
        });
        state.cart = newState;
        localStorage.setItem('cart', JSON.stringify({
          cart: state.cart,
          quantity: state.quantity,
          total: state.total,
          deliveryPrice: state.deliveryPrice,
          payementMode: state.payementMode,
          deliveryMode: state.deliveryMode,
          commandInfo: state.commandInfo,
          deliveryWeight: state.deliveryWeight,
          email : state.email
        }));
      }
    },
    decreaseQuantity: (state, action) => {
      // declare variables
      let products = state.cart;
      let product = action.payload.data;
      //
      const existingProduct = getProductsById(state.cart, product.slug.current);
      let newState = [];
      if (existingProduct) {
        newState = products.map((p) => {
          if (p.slug.current === existingProduct.slug.current) {
            p.quantity = p.quantity > 1 ? p.quantity - 1 : 1;
          }
          return p;
        });
        state.cart = newState;
        localStorage.setItem('cart', JSON.stringify({
          cart: state.cart,
          quantity: state.quantity,
          total: state.total,
          deliveryPrice: state.deliveryPrice,
          payementMode: state.payementMode,
          deliveryMode: state.deliveryMode,
          commandInfo: state.commandInfo,
          deliveryWeight: state.deliveryWeight,
          email : state.email
        }));
      }
    },
    setQuantity: (state, action) => {
      // declare variables
      let products = state.cart;
      let product = action.payload;
      //
      const existingProduct = getProductsById(state.cart, product._id);
      let newState = [];
      if (existingProduct) {
        newState = products.map((p) => {
          if (p._id === existingProduct._id) {
            p.quantity = product.quantity;
          }
          return p;
        });
        state.cart = newState;
      }
    },
    setTotalPrice: (state, action) => {
      let total = 0;
      const TotalProductPrice = (product) => {
        return product.quantity * parseFloat(product.price).toFixed(2);
      };

      state.cart.forEach((el) => {
        total = total + TotalProductPrice(el);
      });

      state.total = total;

      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
      
    },
    setDeliveryMode: (state, action) =>{
      state.deliveryMode = action.payload
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        email : state.email
      }));
    },
    setPayementMode: (state, action) =>{
      state.payementMode = action.payload
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        email : state.email
      }));
    },
    setDeliveryPrice: (state, action) =>{
      state.deliveryPrice = action.payload.price
      state.deliveryWeight = action.payload.weight
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
    },
    setCommandeInfo: (state, action) => {
      state.commandInfo = action.payload
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
    },
    setProgramation: (state, action) => {
      console.log(action.payload)
      state.commandInfo.programation[action.payload.cat] = action.payload.word
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
    },
    setCart : (state, action) => {
      console.log(action)
      state.cart = action.payload.cart
      state.deliveryMode = action.payload.deliveryMode
      state.payementMode = action.payload.payementMode
      state.deliveryPrice = action.payload.deliveryPrice
      state.quantity = action.payload.quantity
      state.total = action.payload.total
      state.commandInfo = action.payload.commandInfo
      state.deliveryWeight = action.payload.deliveryWeight
      state.programation = action.payload.programation
      state.email = action.payload.email


      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        deliveryWeight: state.deliveryWeight,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        email : state.email
      }));
    },
    updateDeliveryPrice: (state, action) => {



        let totalWeight = 0;
        let deliveryPrices = 0;
        const TotalProductWeight = (product) => {
          console.log(JSON.parse(JSON.stringify(product)))
          return product.quantity * product.poids;
        };
  
        state.cart.forEach((el) => {
          totalWeight += TotalProductWeight(el);
        });
  

        if (totalWeight < 100 ) deliveryPrices = 8
        if (totalWeight >= 100 && totalWeight < 500) deliveryPrices = 10
        if (totalWeight >= 500 && totalWeight < 1000) deliveryPrices = 12
        if (totalWeight >= 1000 && totalWeight < 1500) deliveryPrices = 14
        if (totalWeight >= 1500 && totalWeight < 2000) deliveryPrices = 16
        if (totalWeight >= 2000 && totalWeight < 2500 ) deliveryPrices = 18
        if (totalWeight >= 2500 && totalWeight < 3000) deliveryPrices = 20
        if (totalWeight >= 3000 && totalWeight < 3500) deliveryPrices = 22
        if (totalWeight >= 3500 ) deliveryPrices = 24


          state.deliveryPrice = deliveryPrices;//action.payload.price
          state.deliveryWeight = totalWeight;//action.payload.weight
    },
    setEmailToCart: (state, action) => {
      state.email = action.payload
      console.log("email.to.cart", action.payload, state.email)
      localStorage.setItem('cart', JSON.stringify({
        cart: state.cart,
        quantity: state.quantity,
        total: state.total,
        deliveryPrice: state.deliveryPrice,
        payementMode: state.payementMode,
        deliveryMode: state.deliveryMode,
        commandInfo: state.commandInfo,
        deliveryWeight: state.deliveryWeight,
        email : state.email
      }));
    }
  },
});

export const {
  addProductToCart,
  removeProductFromCart,
  changeProductQuantity,
  removeAllProductsFromCart,
  checkQuantity,
  increaseQuantity,
  decreaseQuantity,
  setQuantity,
  setTotalPrice,
  setDeliveryMode,
  setPayementMode,
  setDeliveryPrice,
  setCommandeInfo,
  setCart,
  setProgramation,
  updateDeliveryPrice,
  setEmailToCart
} = cartSlice.actions;

export default cartSlice.reducer;
