import React, { useState, createContext } from "react";

import reduxStore from "../assets/scripts/store/redux";

import { Provider } from "react-redux";
import Footer from "../components/modules/Footer"
import Menu from "../components/modules/Menu"
import Cart from "../components/modules/cart"
import Header from "../components/modules/Header"
import { setConnected, setDisconnect, setData } from "../assets/scripts/store/redux-slices/authentication";
import { setCart } from "../assets/scripts/store/redux-slices/cart";
import eventBus from "../assets/scripts/utils/eventBus";
import "../assets/styles/utils/root.scss";
import { useSelector, useDispatch } from "react-redux";



const DefaultLayout = ({ children, location }) => {

  React.useEffect(() => {

    eventBus.on("routeChange", () => {
      window.scrollTo(0, 0)
    })

  },[])

  return (
    <>
      <Provider store={reduxStore}>
      <script defer type="text/javascript" src="https://api.pirsch.io/pirsch.js"
    id="pirschjs"
    data-code="YYJ9JKodrnJqnb0NqSDiE32H0ZyxKATg"></script>
        <div
          className={
            "bi-layout-content " +
            location.pathname.replace(/[^a-zA-Z0-9]/g, "-").replace("-", "")
          }
          lang="fr"
        >
          <GetData/>
          <Header />
          <Cart />
          <Menu />

          <main data-location={location.pathname}>
            {children}
          </main>
          <Footer />
        </div>
      </Provider>
    </>
  );
};

export default DefaultLayout;


const GetData = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {



    let user = localStorage.getItem("user");

    if(!user){
      user={
        connected: false,
        token: '',
        id:'',
        data:{}
      }
    }else{
      try{
        user = JSON.parse(user);
        // instance.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
      }catch{
        user={
          connected: false,
          token: '',
          id:'',
          data:{}
        }
      }  
    }
    if(user.connected){

      dispatch(setConnected(user))
      dispatch(setData(user.data))
      
    }

    let cart = localStorage.getItem("cart");
    
    if(!cart){
      cart={
        cart: [],
        deliveryMode: null,
        payementMode: null,
        deliveryPrice: null,
        deliveryWeight: null,
        quantity: 0,
        total: null,
        commandInfo: {
          facturation: {
            lastName: "",
            firstName: "",
            corporation:'',
            streetNumber:"",
            street:"",
            city: "",
            postCode: "",
            complement: ""
          },
    
          deliveryInfo: {
            streetNumber:"",
            street:"",
            city: "",
            postCode: "",
            complement: ""
          },
          programation: {
            manufacturer: "", 
            model:"", 
            serialNumber:""
          }
        },
        email : ""
      }
    }else {
      try{
        const tCart = JSON.parse(cart);
        console.log(tCart)
        if(tCart.commandInfo.programation){
          cart = JSON.parse(cart);
        } else {
          cart={
            cart: [],
            deliveryMode: null,
            payementMode: null,
            deliveryPrice: null,
            deliveryWeight: null,
            quantity: 0,
            total: null,
            commandInfo: {
              facturation: {
                lastName: "",
                firstName: "",
                corporation:'',
                streetNumber:"",
                street:"",
                city: "",
                postCode: "",
                complement: ""
              },
        
              deliveryInfo: {
                streetNumber:"",
                street:"",
                city: "",
                postCode: "",
                complement: ""
              },
              programation: {
                manufacturer: "", 
                model:"", 
                serialNumber:""
              },
          email : ""

            }
          }
        }

        // instance.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
      }catch{
        cart={
          cart: [],
          deliveryMode: null,
          payementMode: null,
          deliveryPrice: null,
          deliveryWeight: null,
          quantity: 0,
          total: null,
          commandInfo: {
            facturation: {
              lastName: "",
              firstName: "",
              corporation:'',
              streetNumber:"",
              street:"",
              city: "",
              postCode: "",
              complement: ""
            },
      
            deliveryInfo: {
              streetNumber:"",
              street:"",
              city: "",
              postCode: "",
              complement: ""
            },
            programation: {
              manufacturer: "", 
              model:"", 
              serialNumber:""
            }
          },
          email: "",
        }
      }
    }

    dispatch(setCart(cart))

  }, [])

  return <></>
}