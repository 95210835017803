exports.components = {
  "component---src-components-modules-article-template-js": () => import("./../../../src/components/modules/articleTemplate.js" /* webpackChunkName: "component---src-components-modules-article-template-js" */),
  "component---src-components-modules-assistance-js": () => import("./../../../src/components/modules/assistance.js" /* webpackChunkName: "component---src-components-modules-assistance-js" */),
  "component---src-components-modules-boutique-template-js": () => import("./../../../src/components/modules/boutiqueTemplate.js" /* webpackChunkName: "component---src-components-modules-boutique-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-generales-de-vente-js": () => import("./../../../src/pages/conditions-generales-de-vente.js" /* webpackChunkName: "component---src-pages-conditions-generales-de-vente-js" */),
  "component---src-pages-confirmation-commande-js": () => import("./../../../src/pages/confirmation-commande.js" /* webpackChunkName: "component---src-pages-confirmation-commande-js" */),
  "component---src-pages-confirmation-email-js": () => import("./../../../src/pages/confirmation-email.js" /* webpackChunkName: "component---src-pages-confirmation-email-js" */),
  "component---src-pages-get-colissimo-aaaa-js": () => import("./../../../src/pages/getColissimoAAAA.js" /* webpackChunkName: "component---src-pages-get-colissimo-aaaa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-mes-commandes-js": () => import("./../../../src/pages/mes-commandes.js" /* webpackChunkName: "component---src-pages-mes-commandes-js" */),
  "component---src-pages-mon-espace-js": () => import("./../../../src/pages/mon-espace.js" /* webpackChunkName: "component---src-pages-mon-espace-js" */),
  "component---src-pages-mot-de-passe-oublie-js": () => import("./../../../src/pages/mot-de-passe-oublie.js" /* webpackChunkName: "component---src-pages-mot-de-passe-oublie-js" */),
  "component---src-pages-paiement-commande-js": () => import("./../../../src/pages/paiement-commande.js" /* webpackChunkName: "component---src-pages-paiement-commande-js" */),
  "component---src-pages-piece-non-reference-js": () => import("./../../../src/pages/piece-non-reference.js" /* webpackChunkName: "component---src-pages-piece-non-reference-js" */),
  "component---src-pages-recapitulatif-commande-js": () => import("./../../../src/pages/recapitulatif-commande.js" /* webpackChunkName: "component---src-pages-recapitulatif-commande-js" */),
  "component---src-pages-se-connecter-js": () => import("./../../../src/pages/se-connecter.js" /* webpackChunkName: "component---src-pages-se-connecter-js" */)
}

