import React from "react";

const Icon = ({type})=>{
    // const [dimension, setDimension] = React.useState(window.innerWidth)
    const [dimension, setDimension] = React.useState();
    React.useEffect(() => {
        setDimension(window.innerWidth)
        function handleResize() {
            setDimension(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
    },[])

    return (
        <>
            { // fill it if cart is not empty
                type==="cart" && dimension >= 1024 &&(
<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11 44q-1.2 0-2.1-.9Q8 42.2 8 41V15q0-1.2.9-2.1.9-.9 2.1-.9h5.5v-.5q0-3.15 2.175-5.325Q20.85 4 24 4q3.15 0 5.325 2.175Q31.5 8.35 31.5 11.5v.5H37q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V15h-5.5v4.5q0 .65-.425 1.075Q30.65 21 30 21q-.65 0-1.075-.425-.425-.425-.425-1.075V15h-9v4.5q0 .65-.425 1.075Q18.65 21 18 21q-.65 0-1.075-.425-.425-.425-.425-1.075V15H11v26Zm8.5-29h9v-.5q0-1.9-1.3-3.2Q25.9 7 24 7q-1.9 0-3.2 1.3-1.3 1.3-1.3 3.2ZM11 41V15v26Z"/></svg>
                )
            }{
                type==="login" && dimension >= 1024 && (
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">              
                        <path d="M24 23.95q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM8 40v-4.7q0-1.9.95-3.25T11.4 30q3.35-1.5 6.425-2.25Q20.9 27 24 27q3.1 0 6.15.775 3.05.775 6.4 2.225 1.55.7 2.5 2.05.95 1.35.95 3.25V40Zm3-3h26v-1.7q0-.8-.475-1.525-.475-.725-1.175-1.075-3.2-1.55-5.85-2.125Q26.85 30 24 30t-5.55.575q-2.7.575-5.85 2.125-.7.35-1.15 1.075Q11 34.5 11 35.3Zm13-16.05q1.95 0 3.225-1.275Q28.5 18.4 28.5 16.45q0-1.95-1.275-3.225Q25.95 11.95 24 11.95q-1.95 0-3.225 1.275Q19.5 14.5 19.5 16.45q0 1.95 1.275 3.225Q22.05 20.95 24 20.95Zm0-4.5ZM24 37Z"/>
                    </svg>
                )
            }{ // fill it if cart is not empty
                type==="cart" && dimension < 1024 &&(
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.33331 29.3332C6.79998 29.3332 6.33331 29.1332 5.93331 28.7332C5.53331 28.3332 5.33331 27.8665 5.33331 27.3332V9.99984C5.33331 9.4665 5.53331 8.99984 5.93331 8.59984C6.33331 8.19984 6.79998 7.99984 7.33331 7.99984H11V7.6665C11 6.2665 11.4833 5.08317 12.45 4.1165C13.4166 3.14984 14.6 2.6665 16 2.6665C17.4 2.6665 18.5833 3.14984 19.55 4.1165C20.5166 5.08317 21 6.2665 21 7.6665V7.99984H24.6666C25.2 7.99984 25.6666 8.19984 26.0666 8.59984C26.4666 8.99984 26.6666 9.4665 26.6666 9.99984V27.3332C26.6666 27.8665 26.4666 28.3332 26.0666 28.7332C25.6666 29.1332 25.2 29.3332 24.6666 29.3332H7.33331ZM7.33331 27.3332H24.6666V9.99984H21V12.9998C21 13.2887 20.9055 13.5276 20.7166 13.7165C20.5278 13.9054 20.2889 13.9998 20 13.9998C19.7111 13.9998 19.4722 13.9054 19.2833 13.7165C19.0944 13.5276 19 13.2887 19 12.9998V9.99984H13V12.9998C13 13.2887 12.9055 13.5276 12.7166 13.7165C12.5278 13.9054 12.2889 13.9998 12 13.9998C11.7111 13.9998 11.4722 13.9054 11.2833 13.7165C11.0944 13.5276 11 13.2887 11 12.9998V9.99984H7.33331V27.3332ZM13 7.99984H19V7.6665C19 6.82206 18.7111 6.11095 18.1333 5.53317C17.5555 4.95539 16.8444 4.6665 16 4.6665C15.1555 4.6665 14.4444 4.95539 13.8666 5.53317C13.2889 6.11095 13 6.82206 13 7.6665V7.99984ZM7.33331 27.3332V9.99984V27.3332Z" fill="black"/>
</svg>

                )
            }{
                type==="login" && dimension < 1024 && (
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0002 15.9668C14.5335 15.9668 13.3335 15.5001 12.4002 14.5668C11.4668 13.6335 11.0002 12.4335 11.0002 10.9668C11.0002 9.50013 11.4668 8.30013 12.4002 7.3668C13.3335 6.43346 14.5335 5.9668 16.0002 5.9668C17.4668 5.9668 18.6668 6.43346 19.6002 7.3668C20.5335 8.30013 21.0002 9.50013 21.0002 10.9668C21.0002 12.4335 20.5335 13.6335 19.6002 14.5668C18.6668 15.5001 17.4668 15.9668 16.0002 15.9668V15.9668ZM5.3335 26.6668V23.5335C5.3335 22.689 5.54461 21.9668 5.96683 21.3668C6.38905 20.7668 6.9335 20.3112 7.60016 20.0001C9.08905 19.3335 10.5168 18.8335 11.8835 18.5001C13.2502 18.1668 14.6224 18.0001 16.0002 18.0001C17.3779 18.0001 18.7446 18.1724 20.1002 18.5168C21.4557 18.8612 22.8779 19.3557 24.3668 20.0001C25.0557 20.3112 25.6113 20.7668 26.0335 21.3668C26.4557 21.9668 26.6668 22.689 26.6668 23.5335V26.6668H5.3335ZM7.3335 24.6668H24.6668V23.5335C24.6668 23.1779 24.5613 22.839 24.3502 22.5168C24.1391 22.1946 23.8779 21.9557 23.5668 21.8001C22.1446 21.1112 20.8446 20.639 19.6668 20.3835C18.4891 20.1279 17.2668 20.0001 16.0002 20.0001C14.7335 20.0001 13.5002 20.1279 12.3002 20.3835C11.1002 20.639 9.80016 21.1112 8.40016 21.8001C8.08905 21.9557 7.8335 22.1946 7.6335 22.5168C7.4335 22.839 7.3335 23.1779 7.3335 23.5335V24.6668ZM16.0002 13.9668C16.8668 13.9668 17.5835 13.6835 18.1502 13.1168C18.7168 12.5501 19.0002 11.8335 19.0002 10.9668C19.0002 10.1001 18.7168 9.38346 18.1502 8.8168C17.5835 8.25013 16.8668 7.9668 16.0002 7.9668C15.1335 7.9668 14.4168 8.25013 13.8502 8.8168C13.2835 9.38346 13.0002 10.1001 13.0002 10.9668C13.0002 11.8335 13.2835 12.5501 13.8502 13.1168C14.4168 13.6835 15.1335 13.9668 16.0002 13.9668Z" fill="black"/>
                    </svg>
                )
            }{
                type==="menu" &&  dimension < 1024 && (
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_110_28)">
                            <path d="M6.66675 23.0333V22H25.3334V23.0333H6.66675ZM6.66675 16.5V15.5H25.3334V16.5H6.66675ZM6.66675 10V8.96667H25.3334V10H6.66675Z" fill="#1D1D1D"/>
                            <path d="M4 24V22H28V24H4ZM4 17V15H28V17H4ZM4 10V8H28V10H4Z" fill="#1D1D1D"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_110_28">
                            <rect width="32" height="32" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>

                )
            }{
                type==="left" && (
                    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.61673 0.451985L8.6084 1.46136L2.53444 7.53532L8.6084 13.6093L7.61673 14.6187L0.533397 7.53532L7.61673 0.451985Z" fill="#1D1D1D"/>
                    </svg>

                )
            }{
                type==="right" && (
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.38327 15.548L4.3916 14.5386L10.4656 8.46468L4.3916 2.39072L5.38327 1.38135L12.4666 8.46468L5.38327 15.548Z" fill="#1D1D1D"/>
                    </svg>
                )
            }{
                type==="cross" && (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" type="modal-cross"><path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" fill="black"></path></svg>
                )
            }{
                type==="delete" && (
                    <svg className="icon" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.4167 41.6666C14.6181 41.6666 13.9323 41.3801 13.3594 40.8072C12.7865 40.2343 12.5 39.5659 12.5 38.802V11.7708H10.4167V10.1562H18.4375V8.802H31.5625V10.1562H39.5834V11.7708H37.5V38.802C37.5 39.6006 37.2136 40.2777 36.6406 40.8333C36.0677 41.3888 35.382 41.6666 34.5834 41.6666H15.4167ZM35.8854 11.7708H14.1146V38.802C14.1146 39.1492 14.2448 39.4444 14.5052 39.6874C14.7656 39.9305 15.0695 40.052 15.4167 40.052H34.5834C34.9306 40.052 35.2344 39.9218 35.4948 39.6614C35.7552 39.401 35.8854 39.1145 35.8854 38.802V11.7708ZM20.5729 35.7291H22.1875V16.0416H20.5729V35.7291ZM27.8125 35.7291H29.4271V16.0416H27.8125V35.7291ZM14.1146 11.7708V40.052V38.802V11.7708Z" fill="#1D1D1D"/>
                    </svg>
                )
            }{
                type==="more" && (
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.85 37.5V25.15H10.5V22.85H22.85V10.5H25.15V22.85H37.5V25.15H25.15V37.5H22.85Z" fill="black"/>
                    </svg>
                )
            }{
                type==="less" && (
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 25.15V22.85H37.5V25.15H10.5Z" fill="black"/>
                    </svg>
                )
            }{
                type==="search" && (
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.4125 18.3531L11.6594 12.6C11.2219 12.9792 10.7115 13.2745 10.1281 13.4859C9.54479 13.6974 8.925 13.8031 8.26875 13.8031C6.69375 13.8031 5.35938 13.2562 4.26562 12.1625C3.17188 11.0687 2.625 9.74895 2.625 8.20312C2.625 6.65729 3.17188 5.33749 4.26562 4.24374C5.35938 3.14999 6.68646 2.60312 8.24688 2.60312C9.79271 2.60312 11.1089 3.14999 12.1953 4.24374C13.2818 5.33749 13.825 6.65729 13.825 8.20312C13.825 8.8302 13.7229 9.43541 13.5187 10.0187C13.3146 10.6021 13.0083 11.149 12.6 11.6594L18.375 17.3906L17.4125 18.3531ZM8.24688 12.4906C9.42813 12.4906 10.4344 12.0713 11.2656 11.2328C12.0969 10.3943 12.5125 9.38437 12.5125 8.20312C12.5125 7.02187 12.0969 6.01197 11.2656 5.17343C10.4344 4.33489 9.42813 3.91562 8.24688 3.91562C7.05104 3.91562 6.03385 4.33489 5.19531 5.17343C4.35677 6.01197 3.9375 7.02187 3.9375 8.20312C3.9375 9.38437 4.35677 10.3943 5.19531 11.2328C6.03385 12.0713 7.05104 12.4906 8.24688 12.4906V12.4906Z" fill="black"/>
                    </svg>

                )
            }{
                type==="info" && (
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.65 34H25.65V22H22.65V34ZM24 18.3C24.4667 18.3 24.8583 18.15 25.175 17.85C25.4917 17.55 25.65 17.1667 25.65 16.7C25.65 16.2333 25.4917 15.8333 25.175 15.5C24.8583 15.1667 24.4667 15 24 15C23.5333 15 23.1417 15.1667 22.825 15.5C22.5083 15.8333 22.35 16.2333 22.35 16.7C22.35 17.1667 22.5083 17.55 22.825 17.85C23.1417 18.15 23.5333 18.3 24 18.3ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7167 4 23.95C4 21.2167 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2833 4 24.05 4C26.7833 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44ZM24.05 41C28.75 41 32.75 39.3417 36.05 36.025C39.35 32.7083 41 28.6833 41 23.95C41 19.25 39.35 15.25 36.05 11.95C32.75 8.65 28.7333 7 24 7C19.3 7 15.2917 8.65 11.975 11.95C8.65833 15.25 7 19.2667 7 24C7 28.7 8.65833 32.7083 11.975 36.025C15.2917 39.3417 19.3167 41 24.05 41Z" fill="#1D1D1D"/>
                    </svg>
                )
            }{
                type==="crossMenu" && (
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.2999 25.0999L6.8999 23.6999L14.5999 15.9999L6.8999 8.2999L8.2999 6.8999L15.9999 14.5999L23.6999 6.8999L25.0999 8.2999L17.3999 15.9999L25.0999 23.6999L23.6999 25.0999L15.9999 17.3999L8.2999 25.0999Z" fill="black"/>
                    </svg>
                )
            }{
                type==="PDF" && (
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0334 17.6337H12.2667V14.867H13.8667C14.2222 14.867 14.5167 14.7503 14.75 14.517C14.9834 14.2837 15.1 13.9892 15.1 13.6337V12.0337C15.1 11.6781 14.9834 11.3837 14.75 11.1503C14.5167 10.917 14.2222 10.8003 13.8667 10.8003H11.0334V17.6337ZM12.2667 13.6337V12.0337H13.8667V13.6337H12.2667ZM16.5667 17.6337H19.3667C19.7 17.6337 19.9889 17.517 20.2334 17.2837C20.4778 17.0503 20.6 16.7559 20.6 16.4003V12.0337C20.6 11.6781 20.4778 11.3837 20.2334 11.1503C19.9889 10.917 19.7 10.8003 19.3667 10.8003H16.5667V17.6337ZM17.8 16.4003V12.0337H19.3667V16.4003H17.8ZM22.2334 17.6337H23.4667V14.867H25.1334V13.6337H23.4667V12.0337H25.1334V10.8003H22.2334V17.6337ZM8.66669 25.3337C8.13335 25.3337 7.66669 25.1337 7.26669 24.7337C6.86669 24.3337 6.66669 23.867 6.66669 23.3337V4.66699C6.66669 4.13366 6.86669 3.66699 7.26669 3.26699C7.66669 2.86699 8.13335 2.66699 8.66669 2.66699H27.3334C27.8667 2.66699 28.3334 2.86699 28.7334 3.26699C29.1334 3.66699 29.3334 4.13366 29.3334 4.66699V23.3337C29.3334 23.867 29.1334 24.3337 28.7334 24.7337C28.3334 25.1337 27.8667 25.3337 27.3334 25.3337H8.66669ZM8.66669 23.3337H27.3334V4.66699H8.66669V23.3337ZM4.66669 29.3337C4.13335 29.3337 3.66669 29.1337 3.26669 28.7337C2.86669 28.3337 2.66669 27.867 2.66669 27.3337V6.66699H4.66669V27.3337H25.3334V29.3337H4.66669ZM8.66669 4.66699V23.3337V4.66699Z" fill="black"/>
                    </svg>
                )
            }{
                type === "downarrow" && (
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 29L13.8 18.8L14.6 18L24 27.4L33.4001 18L34.2001 18.8L24 29Z" fill="#1D1D1D"/>
                    </svg>

                )
            }
        </>
    )
}
export default Icon
