import React from "react";
import Button from "../atoms/Button"
import "../../assets/styles/components/modules/article.scss"
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart, setTotalPrice, updateDeliveryPrice } from "../../assets/scripts/store/redux-slices/cart";
import { removeProductFromCart, increaseQuantity, decreaseQuantity, checkQuantity } from "../../assets/scripts/store/redux-slices/cart";
import { Link } from "gatsby";
import formatNumber from "../../assets/scripts/utils/priceNormalisation"
import { urlImageBuilder } from "../../assets/scripts/utils/urlImageBuilder";
import noImage from "../../assets/images/pages/boutique/article/no-image.jpg"
import Accordeon from "../atoms/accordeon"
//import Bubble from "../atoms/Bubbles"
import {PortableText} from '@portabletext/react'


const Article = ({ data, display, interaction=true }) => {
    const [quantity, setQuantity] = React.useState(display === 'onCart' ? data.quantity : 1);
    const dispatch = useDispatch();
    const [isPro, setIsPro] = React.useState(false)
    const user = useSelector((state) => state.authentication)
    const cart = useSelector((state) => state.cart)
    const [ article, setArticle] = React.useState(data)

    
    if (display === 'onCart' && (data.quantity !== quantity )) {
        setQuantity(data.quantity);
    }
    // const history = useHistory()
    const addToCart = () => {
        const datas = { ...article };
        datas.quantity = parseInt(quantity)
        dispatch(checkQuantity())
        dispatch(addProductToCart(datas))
        dispatch(setTotalPrice())

    }
    const removeFromCart = () => {
        const id = {}
        id.slug = data.slug
        dispatch(removeProductFromCart(id));
        dispatch(setTotalPrice())
        dispatch(checkQuantity())

    }


    const changeQty = (isMore) => {
        console.log("cart", data.name)
        if (isMore) {
            setQuantity(quantity + 1)
            if (display === "onCart") {
                dispatch(increaseQuantity({ data }))
            }
        } else {
            if (quantity > 1) {
                setQuantity(quantity - 1)
                if (display === "onCart") {
                    dispatch(decreaseQuantity({ data }))

                }
            }
        }
        dispatch(setTotalPrice())
        dispatch(checkQuantity())
        dispatch(updateDeliveryPrice())
    }

    React.useEffect(() => {
        console.log(data.description)
        // if(user.data.is_pro && (display==="boutique" ||display==="fullPage") ){
            setArticle({
                ...article,
                price: isPro ? article.price * (1 - user.data.reduction / 100) : article.price
            })

            setIsPro(user.data.is_pro)
            console.log("il a vu qeu pro ? ", isPro)
            
        // }
    },[user, isPro, user.data.reduction, user.data.is_pro])


    React.useEffect(() => {
        dispatch(checkQuantity())

    }, [cart.quantity])


    React.useEffect(() => {
        console.log("test", article.price)
    }, [article])

    switch (display) {
        case "boutique":
            return (
            <article className="article-container">
                    <Link className={`article ${display}`} /*onClick={pushToArticle}*/ to={`/boutique/${data.slug.current}`}>
                        <picture>
                            <img src={data.cover ? urlImageBuilder(data.cover._rawAsset._ref, 250, 250) : noImage} alt={"illustration de " + data.name} />
                        </picture>
                        <div className="container">
                            <h2>{data.name ? data.name : "chargement..."}</h2>
                            <div className="bottom">
                                <p>Ref : {data.productReference}</p>
                                <span className={`price ${isPro ? "pro_price" : ""}`}>{data.price ? `${formatNumber(article.price)}€ TTC` : ""}</span>
                                <Button color="" type="qty-select" value={quantity} callBack={changeQty} content="" />
                                <Button color="green" type="text" callBack={addToCart} content="Ajouter au panier" />
                            </div>
                        </div>
                    </Link>
                </article>
            )
        case "onCart":
            return (
                <div className={`${display}`}>
                    <figure>
                        <picture>
                            <img src={data.cover ? urlImageBuilder(data.cover._rawAsset._ref, 120, 120) : noImage} alt={"illustration de " + data.name} />
                        </picture>
                        <figcaption>
                            <div className="top">
                                <p>{data.name ? data.name : "chargement..."}</p>
                                <span>{formatNumber(data.price)}€ TTC</span>
                            </div>
                            {interaction && <div className="bottom">
                                <Button color="" type="qty-select" value={quantity} callBack={changeQty} content="" />
                                <Button color="" type="svg" callBack={removeFromCart} content="delete" />
                            </div>}
                        </figcaption>
                    </figure>
                    <hr />
                </div>
            )
        case "fullPage":
            return (
                <main className="fullPage">
                    <picture>
                        <img src={data.cover ? urlImageBuilder(data.cover._rawAsset._ref, 500, 500) : noImage} alt={"illustration de " + data.name} />
                    </picture>
                    <div className="container">
                        <div className="top">
                            <h1>{data.name ? data.name : "chargement..."}</h1>
                            <section>
                                <div>
                                    <p>Marque</p>
                                    <p>{data.refManufacture.name}</p>
                                </div>
                                <div>
                                    <p>Reference</p>
                                    <p>{data.productReference}</p>
                                </div>
                                <div>
                                    <p>Prix TTC</p>
                                    <p className={isPro ? "pro_price" : ""}>{formatNumber(isPro ? data.price * (1 - user.data.reduction / 100) : data.price)}€</p>
                                </div>
                            </section>
                            <section>
                                <Button color="" type="qty-select" value={quantity} callBack={changeQty} content="" />
                                <Button color="green" type="text" callBack={addToCart} content="Ajouter au panier" />
                            </section>
                            <PortableText value={data.description} />


                            {/* A mettre dans un component */}
                            <div className="content">
                                <h2>Modèles compatibles</h2>
                                <div>
                                    <p className="compatibilite">{data.compatibilities}</p>
                                </div>
                            </div>
                            <div className="content">
                                <h2>Fiche technique</h2>
                                <ul>{[data.name, data.refManufacture.name, data.productReference, data.state].map((el, count) => {
                                    return <li key={count}>
                                        <div className="list">
                                            <div className="left">
                                                <p>{count + 1 === 1 ? "Article" : ""}
                                                    {count + 1 === 2 ? "Fabriquant" : ""}
                                                    {count + 1 === 3 ? "Référence" : ""}
                                                    {count + 1 === 4 ? "État du produit" : ""}</p>
                                            </div>
                                            <div className="right">
                                                <p>{el}</p>
                                            </div>
                                        </div></li>
                                })}</ul>
                                {/* <Accordeon type="technique" title="Fiche technique" content={}/> */}
                            </div>

                        </div>
                    </div>
                </main>
            )
        case "onSearch":
            return (
                <Link to={`/boutique/${data.slug.current}`} className={`${display}`}>
                    <figure>
                        <picture>
                            <img src={data.cover ? urlImageBuilder(data.cover._rawAsset._ref, 120, 120) : noImage} alt={"illustration de " + data.name} />
                        </picture>
                        <figcaption>
                            <p>{data.name ? data.name : "chargement..."}</p>
                            <span>{formatNumber(article.price)}€ TTC</span>
                        </figcaption>
                    </figure>
                    {/* <hr /> */}
                </Link>
            )
        default:
            return ""
    }

}

export default Article