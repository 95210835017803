export const urlImageBuilder = (id, width, height, cropData={bottom: 0,left: 0,right: 0,top: 0, width: 0, height: 0}, type) => {
  const getPixelCropData = (cropData) => {

    const width = cropData?.width ? cropData.width : 1;
    const height = cropData?.height ? cropData.height : 1;
    const left = percentToPx(cropData.left * 100, width);
    const top = percentToPx(cropData.top * 100, height);
    const right = percentToPx(cropData.right * 100, width);
    const bottom = percentToPx(cropData.bottom * 100, height);



    // if value is not number return null to prevent error
    if (isNaN(top)) {
        return null;
    } else {
        return `${parseInt(left)},${parseInt(top)},${
            parseInt(width) - parseInt(left) - parseInt(right) - 1
          },${parseInt(height) - parseInt(top) - parseInt(bottom) - 1}`;
    }

  };

  const percentToPx = (percent, dimension) => {
    return (percent / 100) * dimension;
  };
  const query = ((width ? "?w=" + width : "") +
  (height ? "&h=" + height : "")) + (type === 'webp' ? "&auto=format" : "");

  let test = id.split("-")

  return `https://cdn.sanity.io/images/lngb2k9o/production/${test[1]}-${test[2]}.${test[3]}${query}`;
};

export const urlFileBuilder = (id) => {

  let test = id.split("-")

  return `https://cdn.sanity.io/files/lngb2k9o/production/${test[1]}.${test[2]}`;
};